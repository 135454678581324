<template>
  <div
    class="container"
    v-title
    id="elevatorDis"
    :data-title="$t('i18n.elevatorDis')"
  >
    <div id="outer-title">{{ $t("i18n.elevatorDis") }}</div>
    <jl-chart
      class="chartProvince"
      :columns="option"
      name="option"
      @clickOnChart="clickOnChart"
    ></jl-chart>
    <jl-chart name="option" class="chartCircle" :columns="pieOption"></jl-chart>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import jlChart from "../../components/chart";
export default {
  name: "EleMapView",
  components: {
    jlChart,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const state = reactive({
      option: {
        title: t("i18n.elevatorDis"),
        type: "bar",
        yData: [],
        series: [
          {
            data: [],
            itemStyle: {
              normal: {
                color: function (params) {
                  var index = params.dataIndex;
                  var alternateNumber = 6;
                  var newColor = "";
                  var colorList = [
                    "#268DF1",
                    "#FA583E",
                    "#F69863",
                    "#AE72EF",
                    "#FCE38C",
                    "#89C075",
                  ];
                  if (index < alternateNumber) {
                    newColor = colorList[index];
                  } else {
                    var rowNumber = Math.floor(index / alternateNumber);
                    newColor = colorList[index - rowNumber * alternateNumber];
                  }
                  return newColor;
                },
              },
            },
          },
        ],
      },
      pieOption: {
        title: "",
        type: "pie",
        series: [
          {
            minAngle: 5, //最小的扇区角度（0 ~ 360），用于防止某个值过小导致扇区太小影响交互
            avoidLabelOverlap: true, //是否启用防止标签重叠策略
            radius: ["35%", "55%"],
            itemStyle: {
              normal: {
                label: {
                  formatter: "{b}:{d}%",
                },
              },
            },
            data: [],
          },
        ],
      },
    });

    const clickOnChart = async (row) => {
      state.pieOption.title = row.name;
      let { data } = await proxy.$api.monitor.eleBarsProvince({
        parent: row.name,
      });
      data.map((item) => {
        item.value = item.numbers;
      });
      state.pieOption.series[0].data = data;
    };

    const init = async () => {
      let { data } = await proxy.$api.monitor.eleBars();
      data.map((item) => {
        state.option.series[0].data.push(item.numbers);
        state.option.yData.push(item.name);
      });
      clickOnChart(data[data.length - 1]);
    };

    init();

    return {
      ...toRefs(state),
      clickOnChart,
    };
  },
};
</script>

<style lang="scss">
#elevatorDis {
  .chart {
    display: inline-block;
  }
  .chartProvince {
    width: 70%;
  }
  .chartCircle {
    width: 28%;
  }
}
@media screen and (max-width: 768px) {
  #elevatorDis {
    .chartProvince,
    .chartCircle {
      width: 100%;
    }
  }
}
</style>
